import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import useMe from 'hooks/api/useMe';
import useAuth from 'hooks/api/useAuth';
import Card from 'components/common/Card';
import usePopularProducts from 'hooks/api/usePopularProduct';
import { useRouter } from 'next/router';
import { NoticeContext } from 'contexts/notification';
import Head from 'next/head';
import useOauth from 'hooks/common/useOauth';
import Empty from 'components/core/Empty';
import Loader from 'components/common/Loader';
import { useTranslation } from 'next-i18next';
import { incrementReferrer } from 'services/user.service';
import { AppContext } from 'contexts/';
import { CURRENCY_ROUTES, AVAILABLE_REFERRERS } from 'constants/';
import { useMutation } from 'react-query';
import classNames from 'classnames';

import { Quantico } from 'next/font/google';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TermsModal from './components/TermsModal';
import styles from './index.module.scss';
import EmailDeliveryNote from './components/EmailDeliveryNote';

interface GeneralLayoutProps {
  title: string,
  children: ReactNode,
  description: string,
  keywords: string
}

const RedirectRoutes = [
  '/orders',
];

const notShowYouMayAlsoLike = [
  '/about-us', '/terms', '/privacy-policy', '/shipping-and-payment', '/contact-us',
];

const notShowJoistik = [
  '/about-us', '/terms', '/privacy-policy', '/shipping-and-payment', '/checkout', '/contact-us', '/buy-gift-cards/with-crypto',
];

const quantico = Quantico({
  weight: '400',
  subsets: ['latin'],
});

interface GeneralLayoutProps extends IBaseSEO {
  children: ReactNode
}

const GeneralLayout = ({
  children, title: incomeTitle, description: incomeDescription, keywords: incomeKeywords, translation,
}: GeneralLayoutProps) => {
  const { currency, user: authorized } = useContext(AppContext);
  const { isLoading, data: me } = useMe({ enabled: !authorized });
  const { loading, user } = useOauth();
  const [enabledPopularProduct, setEnabledPopularProduct] = useState(true);
  const { data, isLoading: popularLoading } = usePopularProducts({ enabled: enabledPopularProduct }, currency);
  const router = useRouter();
  const { query, replace, route, push } = router;
  const { authVerify } = useAuth();
  const { message } = useContext(NoticeContext);
  const [termModalVisible, updateTermModalVisible] = useState(false);
  const { t } = useTranslation('layout');
  const { locale } = useRouter();
  const { mutateAsync: incrementRefererMutation } = useMutation(incrementReferrer);
  const popularIsLoading = useMemo(() => {
    if (popularLoading) return <div className={styles.loader}><Loader /></div>;
    return null;
  }, [popularLoading]);

  const handleReferrerIncrement = useCallback((incomeReferrer: string) => {
    void incrementRefererMutation(incomeReferrer);
  }, [incrementRefererMutation]);

  const description = useMemo(() => {
    if (locale && locale !== 'en' && translation?.[locale] && translation[locale].seoDescription) return translation[locale].seoDescription;
    return incomeDescription;
  }, [incomeDescription, locale, translation]);

  const title = useMemo(() => {
    if (locale && locale !== 'en' && translation?.[locale] && translation?.[locale].seoTitle) return translation[locale].seoTitle;
    return incomeTitle;
  }, [incomeTitle, locale, translation]);

  const keywords = useMemo(() => {
    if (locale && locale !== 'en' && translation?.[locale] && translation?.[locale].seoKeywords) return translation[locale].seoKeywords;
    return incomeKeywords;
  }, [incomeKeywords, locale, translation]);

  useEffect(() => {
    if (data) setEnabledPopularProduct(!data);
  }, [setEnabledPopularProduct, data]);

  useEffect(() => {
    setEnabledPopularProduct(true);
  }, [setEnabledPopularProduct, currency]);

  useEffect(() => {
    if (typeof me?.terms === 'boolean' && !me?.terms) {
      updateTermModalVisible(true);
    }
  }, [me?.terms]);

  useEffect(() => {
    if (isLoading || me || loading || user) return;
    const foundRoute = RedirectRoutes.includes(route);
    if (foundRoute) push('/login').then(console.log).catch(console.log);
  }, [user, route, push, loading, isLoading, me]);

  useEffect(() => {
    if (user && CURRENCY_ROUTES.includes(router?.route) && router?.query?.currency !== user.currency) {
      void router.replace({
        query: { ...router.query, currency: user.currency },
      });
    }
    if (!user && CURRENCY_ROUTES.includes(router?.route) && router?.query?.currency !== currency) {
      void router.replace({
        query: { ...router.query, currency },
      });
    }
  }, [router, me, user, currency]);

  useEffect(() => {
    if (query.verify) {
      authVerify(query.verify as string).then(async () => {
        await replace('/');
        message('You successfully confirmed the email', 'success');
      }).catch(() => message('Sorry, your email was not verified', 'error'));
    }
  }, [authVerify, message, query.verify, replace]);

  useEffect(() => {
    const { referrer } = router.query;
    if (referrer && AVAILABLE_REFERRERS.includes(referrer.toString())) {
      handleReferrerIncrement(referrer.toString());
    }
  }, [handleReferrerIncrement, router.query]);

  const cardRender = useCallback((item: Product.Item, idx: number) => (
    <Card key={idx.toString()} {...item} url={`/product/${item.link}`} />
  ), []);

  return (
    <div className={styles.layout}>
      <Head>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
      </Head>
      <TermsModal
        visible={termModalVisible}
        onConfirm={() => updateTermModalVisible(false)}
      />
      {
        loading
          ? (
            <div className={styles.empty}>
              <Empty>
                <>
                  <span className={styles.emptyDescription}>
                    {t('info')}
                  </span>
                  <Loader />
                </>
              </Empty>
            </div>
          )
          : (
            <>
              <Header />
              <div className={styles.main}>
                {
                  notShowJoistik.includes(route)
                    ? null : (
                      <div>
                        <div className={styles.joistek1} />
                        <div className={styles.joistek2} />
                      </div>
                    )
                }
                <EmailDeliveryNote />
                {children}
                {
                  notShowYouMayAlsoLike.includes(route) ? null
                    : (
                      <div>
                        <div className={styles.titleContainer}>
                          <h1 className={classNames(styles.title, quantico.className)}>{t('youMayAlsoLike')}</h1>
                        </div>
                        <div className={styles.allCardContainer}>
                          { data ? data.map(cardRender) : null }
                          {popularIsLoading}
                        </div>
                      </div>
                    )
                }
              </div>
              <Footer />
            </>
          )
      }
    </div>
  );
};

export default GeneralLayout;
